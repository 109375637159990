import React from "react";
import styles from "./styles.module.scss";
const Input = (props) => {
  // let inputClass = "";

  // if (!props.error && props.touched) {
  //   inputClass = styles.success;
  // } else if (props.error && !props.touched) {
  //   inputClass = "";
  // } else if (props.error && props.touched) {
  //   inputClass = styles.error;
  // }
  return (
    <div
      className={`${styles.input} ${styles.rst__clr} ${props.className}`}
      style={props.style}
    >
      {/* <div className={`${styles.header} d-flex`}>
        <Label className={`${styles.label}`} htmlFor={props.id}>
          {props.label}
        </Label>
        {props.tooltip && (
          <TooltipConmponent
            tooltipplacement={props.tooltipplacement ?? "right"}
            tooltip={props.tooltip ?? ""}
            tooltipIcon={props.tooltipIcon ?? "/assets/vectors/fi_info.svg"}
            tooltipIconWidth={props.tooltipIconWidth ?? "20px"}
            tooltipIconHeight={props.tooltipIconHeight ?? "20px"}
            tooltipClass={props.tooltipClass ?? ""}
          />
        )}
        {props.switch ? (
          <Switch
            className={`${props.switchClass}`}
            checked={props.switchValue}
            onChange={props.switchChangeEvent}
          />
        ) : (
          ""
        )}
        {props.seconLabel && (
          <Label className={`${styles.labeltwo} ml-2`} htmlFor={props.id}>
            ({props.seconLabel})
          </Label>
        )}
      </div> */}
      <div>
        <div style={{ position: "relative" }}>
          <input
            disabled={props.disable || false}
            onBlur={props.onBlur}
            className={`
                ${!props.error && props.touched ? styles.success : ""}
                ${props.error && props.touched ? styles.error : ""}
            `}
            onChange={props.onChange}
            // defaultValue={props.value}
            type={props.type || "text"}
            name={props.name}
            id={props.id}
            value={props.value}
            placeholder={props.placeHolder}
          />
          {/* <span className={styles.icon}>{props.input_icon}</span> */}
          {props.input_icon && props.input_icon_type === "text" && (
            <p className={`${styles.inputIconText} ${styles.inputIcon}`}>
              {props.input_icon}
            </p>
          )}
          {/* {props.input_icon && props.input_icon_type === "iconImage" && (
            <p className={`${styles.inputIconText} ${styles.inputIcon}`}>
              {props.input_icon}
            </p>
          )} */}
        </div>
        {props.touched && !props.error && (
          <span className={styles.correct}></span>
        )}
      </div>
      <div>
        {props.helpText && props.helpText !== "" && <>{props.helpText}</>}
      </div>
      <div>
        {props.error && props.touched && (
          <small
            style={{ position: props.errorRelative ? "relative" : "absolute" }}
            className={`text-danger pl-1`}
          >
            {props.error}
          </small>
        )}
      </div>
    </div>
  );
};

export default Input;
