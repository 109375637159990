import React from "react";
import styles from "./styles.module.scss";

const SwitchField = (props) => {
  return (
    <>
      <label
        className={`${styles.switch} .switch__clr ${styles.rst__clr} ${props.className}`}
        style={props.style}
      >
        <input
          onChange={props.onSwitchChange}
          disabled={props.disabled}
          type="checkbox"
          checked={props.checked}
        />
        <span
          className={`${styles.slider} ${
            props.squared ? props.squared : styles.round
          }`}
        ></span>
      </label>
    </>
  );
};

export default SwitchField;
