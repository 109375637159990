import React from "react";
import styles from "./resets.module.scss";

export const Label = ({
  title,
  className,
  span_text,
  children,
  LabelinfoTextComponent,
  renderComponent,
  ...props
}) => {
  return (
    <div className={`${styles.label_body}  rst__clr`}>
      <label className={`${styles.label} ${className}`}>
        <span className={`${styles.section_one}`}>
          <span className={`${styles.title}`}>{title}</span>
          {LabelinfoTextComponent ? (
            <span className={styles.label_info}>{LabelinfoTextComponent}</span>
          ) : (
            ""
          )}{" "}
        </span>
        {renderComponent ? (
          <span className={styles.render_components}>{renderComponent}</span>
        ) : (
          ""
        )}{" "}
      </label>
    </div>
  );
};
