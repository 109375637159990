import React from 'react';
import { Label } from '../utils';
import Switch from '../Switch';
// import SVG from "react-inlinesvg";
import styles from './styles.module.scss';

const TextArea = (props) => {
    return (
        <div
            className={`${styles.input} ${styles.rst__clr} ${props.className}`}
            style={props.style}
        >
            <div className={!props?.bit ? '' : `${styles.header} d-flex`}>
                <Label className={`${styles.label}`} htmlFor={props.id}>
                    {props.label}
                    {props.switch ? (
                        // {(1) ? (
                        <Switch
                            className={`${props.switchClass}`}
                            checked={props.switchValue}
                            onChange={props.switchChangeEvent}
                        />
                    ) : (
                        ''
                    )}
                </Label>
                {props.seconLabel && (
                    <Label
                        className={`${styles.labeltwo} ml-2`}
                        htmlFor={props.id}
                    >
                        ({props.seconLabel})
                    </Label>
                )}
                {/* <label className={styles.label} htmlFor={props.id}>
            {props.label}
          </label> */}
            </div>
            <div>
                <div>
                    <textarea
                        disabled={props.disable || false}
                        onBlur={props.onBlur}
                        className={`
                ${!props.error && props.touched ? styles.success : ''}
                ${props.error && props.touched ? styles.error : ''}
            `}
                        onChange={props.onChange}
                        // defaultValue={props.value}
                        value={props.value}
                        type={props.type || 'text'}
                        name={props.name}
                        id={props.id}
                        rows={props.rows ?? '4'}
                        placeholder={props.placeHolder}
                        maxLength={props.maxLength}
                    />
                    {/* <span className={styles.icon}>{props.input_icon}</span> */}
                </div>

                {props.touched && !props.error && (
                    <span className={styles.correct}></span>
                )}
            </div>
            {props.error && props.touched && (
                <small
                    style={{
                        position: props.errorRelative ? 'relative' : 'absolute',
                    }}
                    className={`text-danger pl-1`}
                >
                    {props.error}
                </small>
            )}
        </div>
    );
};
export default TextArea;
